// First override some or all individual color variables
// https://coolors.co/d1495b-e55812-95c623-26f0f1-282c34
$primary: #26F0F1;
$secondary: #95C623;
$success: #E55812;
$info: #13101c;
$warning: #D1495B;
$danger: #D1495B;
$light: #F6E8EA;
$dark: #282c34;

$body-bg: #282c34;
$bg-color: #282c34;


// Then add them to your custom theme-colors map, together with any additional colors you might need
$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  dark: $dark,
  bg: $bg-color
  // add any additional color below
);