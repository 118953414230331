// Waves - https://codepen.io/mburakerman/pen/eRZZEv

/* KEYFRAMES */
@keyframes squid-float {
	0% {
		transform: translatey(0px);
    opacity: 0.8;
    filter: invert(77%) sepia(99%) saturate(1357%) hue-rotate(128deg) brightness(100%) contrast(89%); // $primary - #26F0F1
	}

	50% {
		transform: translatey(-20px);
    opacity: 0.8;
    // filter: invert(25%) sepia(12%) saturate(736%) hue-rotate(183deg) brightness(95%) contrast(91%); // #3E4451
    filter: invert(13%) sepia(18%) saturate(640%) hue-rotate(182deg) brightness(88%) contrast(87%); // $dark - #282c34
	}

	100% {
		transform: translatey(0px);
    opacity: 0.8;
    filter: invert(77%) sepia(99%) saturate(1357%) hue-rotate(128deg) brightness(100%) contrast(89%); // $primary - #26F0F1
	}
}

@keyframes animateBubble {
  0% {
      margin-top: 150px;
  }
  100% {
      margin-top: -100px;
  }
}

@keyframes sideWays { 
  0% { 
      margin-left:0px;
  }
  100% { 
      margin-left:50px;
  }
}

@keyframes waves {
  0% { transform: translate(-50%,-75%) rotate(0deg); }
  100% { transform: translate(-50%,-75%) rotate(360deg); }
}

/* ANIMATIONS */

.x1 {
  animation: animateBubble 25s linear infinite, sideWays 2s ease-in-out infinite alternate;
  left: 0%;
  top: 5%;
  transform: scale(0.6);
}

.x2 {
  animation: animateBubble 20s linear infinite, sideWays 4s ease-in-out infinite alternate;
  left: 115%;
  top: 80%;
  transform: scale(0.4);
}

.x3 {
  animation: animateBubble 28s linear infinite, sideWays 2s ease-in-out infinite alternate;
  left: 10%;
  top: 40%;
  transform: scale(0.7);
}

.x4 {
  animation: animateBubble 22s linear infinite, sideWays 3s ease-in-out infinite alternate;
  left: 20%;
  top: 0;
  transform: scale(0.3);
}

.x5 {
  animation: animateBubble 29s linear infinite, sideWays 4s ease-in-out infinite alternate;
  left: -30%;
  top: 50%;
  transform: scale(0.5);
}

.x6 {
  animation: animateBubble 21s linear infinite, sideWays 2s ease-in-out infinite alternate;
  left: 50%;
  top: 0;
  transform: scale(0.8);
}

.x7 {
  animation: animateBubble 20s linear infinite, sideWays 2s ease-in-out infinite alternate;
  left: 65%;
  top: 70%;
  transform: scale(0.4);
}

.x8 {
  animation: animateBubble 22s linear infinite, sideWays 3s ease-in-out infinite alternate;
  left: 80%;
  top: 10%;
  transform: scale(0.3);
}

.x9 {
  animation: animateBubble 29s linear infinite, sideWays 4s ease-in-out infinite alternate;
  left: 90%;
  top: 50%;
  transform: scale(0.6);
}

.x10 {
  animation: animateBubble 26s linear infinite, sideWays 2s ease-in-out infinite alternate;
  left: -15%;
  top: 80%;
  transform: scale(0.3);
}

/* OBJECTS */

.squid-logo {
  height: 30vmin;
  pointer-events: none;
  animation: squid-float 6s ease-in-out infinite;
}

.bubble {
  border-radius: 50%;
  box-shadow: 0 5px 7.5px rgba(0, 0, 0, 0.2), inset 0px 2.5px 7.5px 0.8px rgba(255, 255, 255, 0.2);
  height: 50px;
  position: absolute;
  width: 50px;
}

.bubble:after {
  background: radial-gradient(ellipse at center,  rgba(40,44,52,0.2) 0%,rgba(40,44,52,0) 70%); /* W3C */

  border-radius: 50%;
  box-shadow: inset 0 10px 7.5px rgba(69,250,251, 0.2);

  content: "";
  height: 45px;
  left: 2.5px;
  position: absolute;
  width: 45px;
}

.bubble.pink:after {
  box-shadow: inset 0 10px 7.5px rgba(143,48,116, 0.2);
}

.wave-container {
  width: 100%;
  height: 60vh;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient($primary, $dark);
  overflow: hidden;
}

.wave {
  position: absolute;
  width: 200vh;
  height: 200vh;
  top: 0;
  left: 50%;
  transform: translate(-50%,-75%);
  background: rgba(40,44,52, 0.25);
  border-radius: 36%;
  animation: waves 35s infinite linear;
}

.wave-2 {
  width: 150vh;
  transform: translate(-50%,-50%);
  animation: waves 30s linear infinite;
  border-radius: 46%;
  background: rgba(40,44,52, 0.3);
}
.wave-3 {
  width: 175vh;
  animation: waves 38s linear infinite;
  border-radius: 42%;
  background: rgba(40,44,52, 0.4);
}