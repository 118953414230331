@import 'variables';
@import 'animations';

.text-left {
  text-align: left;
}

.no-underline {
  text-decoration: none;
}

.App {
  text-align: center;
  height: 100vh;
  overflow-y: scroll;
  position: relative;
}

.btn-fab {
  position: absolute;
  z-index: 100;
  bottom: 0px;
  right: 0px;
  height: 48px;
  width: 48px;
  border-radius: 50%;
}

.navbar {
  font-family: 'Michroma', sans-serif;
  background-color: $bg-color;
  border-bottom: 3px solid $secondary;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  .nav-item {
    padding-right: 1.5rem;
  }
  
  .nav-link {
    color: $secondary;
    font-weight: bold;
    border-bottom: 3px solid $dark;

    &:hover, &.active {
      color: $danger;
      border-bottom: 3px solid $danger;
    }
  }
}

.squid-and-bubbles {
  padding-top: 3rem;
  position: relative;
  z-index: 1;
}

.company-title {
  position: relative;
  color: $light;
  i {
    font-family: 'Press Start 2P', cursive;
    color: $secondary;
    padding: 0 4px;
    vertical-align: text-bottom;
  }
}

.section {
  padding: 5rem 0;
  border-bottom: 3px solid $warning;
  h3 {
    border-bottom: 3px solid $warning;
  }
}

#company-header {
  padding: 5rem 0 3rem 0;
}

#company-services {
  position: relative;
}

#company-services, #company-contact {
  background-color: $dark;
  color: $light;
}

#company-testimonials {
  background-color: $light;
  color: $dark;
}

.card {
  border: 3px solid $secondary;
  color: $light;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-title {
  font-weight: bold;
  padding-bottom: 6px;
  color: $secondary;
}

.card-subtitle {
  font-weight: bold;
  padding: 0 0 6px 0;
  font-style: italic;
  border-bottom: 3px solid $secondary;
}

ul > li {
  text-align: left;
}

footer {
  font-family: 'Michroma', sans-serif;
  color: $light;
  background-color: $dark;
}
.img-avatar {

  img {
    max-width: 200px;
    max-height: 200px;
    border-radius: 50%;

  }
}

.blockquote {
  font-size: 0.9rem;
}